import React from 'react'

import './style/acceptable-use-policy.scss'

const AcceptableUsePolicy = () => (
  <div className='row-wrapper copy-right acceptable-use-policy'>
    <div className='container'>
      <section className='policy-agree'>
        <div className='row'>
          <div className='col-sm-12'>
            <h2>WebsiteBuilder Acceptable Use</h2>
            <p>This Acceptable Use Policy ("AUP") governs your use of the Services and is incorporated by reference into WebsiteBuilder’s
              <a href='/terms/terms-of-service'> Terms of Services</a>. Unless otherwise stated, defined terms in this AUP have the meanings provided to them in the
              Terms of Services. WebsiteBuilder may modify the AUP at any time without notice.You shall use the Services only for lawful purposes. Transmission, storage,
              or display of any information, data, or material in violation of applicable laws or regulations, including without limitation the laws of the Commonwealth
              of Massachusetts, is prohibited. WebsiteBuilder reserves the right to terminate the Services for any Customer that exposes WebsiteBuilder to legal
              liability or threatens its ability to provide services to other customers. You agree to indemnify and hold WebsiteBuilder harmless from any claims
              resulting from your use of the Services.</p>
          </div>
        </div>
      </section>
    </div>

    <div className='container'>
      <section className='page-terms_list'>
        <h4>QUICK LINKS</h4>
        <p className='lead'><a href='#PROHIBITEDUSES'>Prohibted Uses</a></p>
        <p>You may not use the Services to publish content or engage in activity that is harmful to others or illegal under applicable law. Any content that, in our
          judgment, violates our Terms of Service, including this AUP, in any manner may be removed from our servers (or otherwise disabled), with or without notice. Examples of prohibited content and activities can be found in this section.</p>

        <p className='lead'><a href='#ZEROTOLERANCESP'>Zero Tolerance Spam Policy</a></p>
        <p>WebsiteBuilder maintains a zero tolerance policy for use of its network or services to send unsolicited bulk or commercial e-mail, or the sending, assisting,
          or commissioning the transmission of commercial e-mail that does not comply with the U.S. CAN-SPAM Act of 2003 ("SPAM"). Please review this section to avoid action under our AUP.</p>

        <p className='lead'><a href='#DEFAMATIONANDOB'>Defamation and Objectionable Content</a></p>
        <p>WebsiteBuilder respects that the Internet provides a forum for free and open discussion and dissemination of information.  Accordingly, we generally do not
          screen, monitor or control customer content.  However, if we are made aware of content that violates the law or this AUP, we will consider the matter and take
          appropriate action as determined in our sole discretion balancing the merits of the complaint with the right to freedom of expression.</p>

        <p className='lead'><a href='#ENFORCEMENT'>Enforcement</a></p>
        <p>WebsiteBuilder may terminate your Services with or without notice upon any violation of this AUP.  This section describes how WebsiteBuilder may enforce this AUP.</p>

        <p className='lead'><a href='#REPORTINGVIOLAT'>Reporting AUP Violations</a></p>
        <p>If you feel you have discovered a violation of our AUP, please review this section to learn about our reporting procedures.</p>
      </section>
    </div>

    <div className='container tos'>
      <h1 className='h2'>WebsiteBuilder Acceptable Use</h1>

      <div className='row'>
        <div className='col-xs-12'>
          <ul className='legal'>
            <li className='item' id='PROHIBITEDUSES'>
              <p><span className='didact-bold'>Prohibted Uses</span></p>
            </li>

            <ul className='sub-lists'>

              <li className='item' id='aNoIllegalorHar'>
                <p><span className='didact-bold'>No Illegal or Harmful Use</span> - You may not use the Services to publish content or engage in activity that is harmful to
                others or illegal under applicable law, including without limitation in connection with any of the following illegal, harmful or fraudulent activities:</p><p />
              </li>

              <ul>

                <li className='item' id='iDisclosingpriv'>
                  <p>Disclosing private sensitive personal information about others;</p>
                </li>

                <li className='item' id='iiDistributingm'>
                  <p>Distributing malware or other malicious code;</p>
                </li>

                <li className='item' id='iiiEngaginginth'>
                  <p>Engaging in the unlawful distribution of controlled substances and drug contraband;</p>
                </li>

                <li className='item' id='ivEngaginginthe'>
                  <p>Engaging in the unlawful distribution of prescription medications, including, but not limited to, promotion, marketing, or sale of prescription medications without a valid prescription;</p>
                </li>

                <li className='item' id='vGambling'>
                  <p>Gambling;</p>
                </li>

                <li className='item' id='viHostingchildp'>
                  <p>Hosting, distributing or linking to child sexual abuse material (CSAM) or content that is harmful to minors (CSAM will be suspended immediately without notice and reported to law enforcement or the National Center for Missing and Exploited Children);</p>
                </li>

                <li className='item' id='viiHostingFOREX'>
                  <p>Hosting FOREX, E-Gold Exchange, Second Life/Linden Exchange, Ponzi, MLM/Pyramid Scheme, High-Yield Interest Programs (HYIP) or related sites;</p>
                </li>

                <li className='item' id='viiiHostingorli'>
                  <p>Hosting or linking to a website intended to deceive the public including, but not limited to sites listed at <a href='http://aa419.org/'>aa419.org</a> &amp; <a href='http://escrow-fraud.com/'>escrow-fraud.com</a>;</p>
                </li>

                <li className='item' id='ixHostingorlink'>
                  <p>Hosting or linking to an anonymous proxy server; </p>
                </li>

                <li className='item' id='xInfringingupon'>
                  <p>Infringing upon the Intellectual Property Rights of Others.This includes, but is not limited to, the unauthorized copying or distribution of movies,
                    music, books, photographs, software/warez, or any other copyrighted work. If you believe that your intellectual property rights are being infringed
                    upon, please email us at <a href='mailto:abuse@WebsiteBuilder.com'>abuse@WebsiteBuilder.com</a>; </p>
                </li>

                <li className='item' id='xiMoneylaunderi'>
                  <p>Money laundering;</p>
                </li>

                <li className='item' id='xiiPhishingoren'>
                  <p>Phishing or engaging in identity theft;</p>
                </li>

                <li className='item' id='xiiiSellingweap'>
                  <p>Selling weapons or ammunition;</p>
                </li>

              </ul>

              <li className='item' id='bNoUnauthorized'>
                <p><span className='didact-bold'>No Unauthorized System Access or Network Abuse</span> - You may not use the Services to gain access into any network or system without permission. Prohibited activities include:</p>
              </li>

              <ul>

                <li className='item' id='iAccessinganoth'>
                  <p>Accessing another network without permission, to probe or scan for vulnerabilities or breach security or authentication measures;</p>
                </li>

                <li className='item' id='iiAttackingothe'>
                  <p>Attacking other networks (i.e. Denial of Service (DoS) attacks);</p>
                </li>

                <li className='item' id='iiiIntercepting'>
                  <p>Intercepting or monitoring data without permission;</p>
                </li>

                <li className='item' id='ivRunningafiles'>
                  <p>Running a file sharing site;</p>
                </li>

                <li className='item' id='vRunninganysoft'>
                  <p>Running any software that interfaces with an IRC (Internet Relay Chat) network;</p>
                </li>

                <li className='item' id='viUsinganydeepl'>
                  <p>Using any deep-link, page-scrape, robot, crawl, index, spider, offline reader, click spam, macro programs, internet agent, or other automatic device
                    program, algorithm or methodology which does the same things, to use, access, copy, index, acquire information, generate impressions or clicks, input
                    information, store information, search, generate searches, or monitor any portion of the WebsiteBuilder’s website or servers for any unauthorized purpose;</p>
                </li>

              </ul>

              <li className='item' id='cDontHamperSyst'>
                <p><span className='didact-bold'>Don't Hamper System Performance</span> - Consuming excessive amount of server resources is prohibited. This leads to server
                performance issues and may cause a disruption to our systems or other customers. Use of any scripts or processes that may adversely impact our systems is
                prohibited. </p>
              </li>

              <li className='item' id='dNoAdultContent'>
                <p><span className='didact-bold'>No Adult Content</span> - You may not use the Services to distribute pornography or other adult-related content or offer any escort services.</p>
              </li>

              <li className='item' id='eNoStorageofBac'>
                <p><span className='didact-bold'>No Storage of Backups</span> - Backing up personal data to a hosting account is prohibited. Our Services are designed to
                host your web site, not serve as a data repository. WebsiteBuilder reserves the right to remove backups from your hosting account.</p>
              </li>

            </ul>

            <li className='item' id='ZEROTOLERANCESP'>
              <p><span className='didact-bold'>Zero Tolerance Spam Policy</span> </p>
            </li>

            <ul className='sub-lists'>

              <li className='item' id='aYoumaynotuseth'>
                <p>You may not use the Services to send spam or bulk unsolicited messages.  WebsiteBuilder maintains a zero tolerance policy for use of its network or
                  services in any manner associated with the transmission, distribution or delivery of any bulk e-mail, including unsolicited bulk or unsolicited
                  commercial e-mail, or the sending, assisting, or commissioning the transmission of commercial e-mail that does not comply with the U.S. CAN-SPAM Act of
                  2003 ("SPAM").</p>
              </li>

              <li className='item' id='bSafelistspurch'>
                <p>"Safe lists," purchased lists, and selling of lists will be treated as SPAM. We may terminate the account of any User who sends out SPAM with or without notice. </p>
              </li>

              <li className='item' id='cWebsitesadvert'>
                <p>
                  Websites advertised via SPAM (i.e. Spamvertised) may not be hosted on our servers. This provision includes, but is not limited to, SPAM sent via fax,
                  phone, postal mail, email, instant messaging, or usenet/newsgroups. No organization or entity listed in the
                  <a href='http://www.spamhaus.org/rokso/'> ROKSO</a> may use our Services. Any User account which results in our IP space being blacklisted will be immediately suspended and/or terminated.</p>
              </li>

              <li className='item' id='dPleasefamiliar'>
                <p>Please familiarize yourself with the CAN-SPAM Act.  Information about the CAN-SPAM Act may be found by accessing the following link:
                  <a href='https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business'> https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business</a></p>
              </li>

            </ul>

            <li className='item' id='DEFAMATIONANDOB'>
              <p><span className='didact-bold'>Defamation and Objectionable Content</span></p><p>WebsiteBuilder values the freedom of expression and encourages Users to be
                respectful with the content they post.  As a webhost, WebsiteBuilder is not a publisher of User content and is generally not in a position to investigate
                the veracity of individual defamation claims or to determine whether certain material, which WebsiteBuilder may find objectionable, should be censored.
                Accordingly, WebsiteBuilder generally requires a court order from a court of competent jurisdiction, as determined by WebsiteBuilder in its sole
                discretion, to take down alleged defamatory or objectionable content.  However, WebsiteBuilder reserves the right to disable or remove any content to
                prevent harm to others or to WebsiteBuilder, as determined in WebsiteBuilder's sole discretion.</p>
            </li>

            <li className='item' id='ENFORCEMENT'>
              <p><span className='didact-bold'>Enforcement</span></p>
            </li>

            <ul className='sub-lists'>

              <li className='item' id='aRESELLERS'>
                <p><span className='didact-bold'>Resellers.</span></p>
              </li>

              <ul>

                <li className='item' id='iIfthereisaviol'>
                  <p>If there is a violation of this AUP by a User of a Reseller, WebsiteBuilder will suspend the account in question and notify the Reseller so that the
                    Reseller can address the matter with the User. The occurrence of additional violations on a User account may result in the immediate suspensions or
                    termination of your Reseller account.</p>
                </li>

              </ul>

              <li className='item' id='bDIRECTCUSTOMER'>
                <p><span className='didact-bold'>Direct Customers.</span></p>
              </li>

              <ul>

                <li className='item' id='iYourServicesma'>
                  <p>Your Services may be terminated with or without notice upon any violation of this AUP. </p>
                </li>

              </ul>

              <li className='item' id='cIfapplicablevi'>
                <p>If applicable, violations will be reported to the appropriate law enforcement agency. </p>
              </li>

              <li className='item' id='dAfailuretoresp'>
                <p>A failure to respond to an email from our compliance team within forty-eight (48) hours, or as otherwise specified in the email, may result in the suspension or termination of your Services.</p>
              </li>

              <li className='item' id='eWereservetheri'>
                <p>We reserve the right to enforce, or not enforce, this AUP in our sole discretion.</p>
              </li>

            </ul>

            <li className='item' id='REPORTINGVIOLAT'>
              <p><span className='didact-bold'>Reporting Violations of this AUP.</span></p><p>If you feel you have discovered a violation of our AUP please email us at
                <a href='mailto:abuse@WebsiteBuilder.com'> abuse@WebsiteBuilder.com</a>. We strive to make the Web a better place and consider the abuse of our services
                a serious matter. We investigate and record all reports of abuse submitted to us. Where appropriate, we may take action if a violation of any of our
                terms of service is confirmed or when required to do so if an activity violates the policies of ICANN or appropriate registry. We may request additional
                information from you about your report. However, please note we may not be able to provide you with specific updates in response to your specific report due to privacy reasons. </p>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default AcceptableUsePolicy
